import moment from 'moment';
import BlockScroller from "../BlockScroller";
import ErrorBlock from "../ErrorBlock";
import {IBlockComponentProps} from "../_Block";

interface IPageBlockProps extends IBlockComponentProps {
    blockObj: IPageBlockObj
}

export default function PageBlock(props: IPageBlockProps) {
    try {
        const title = props.blockObj.pageTitle.replace("/Page.?$/is", "");

        return <>
            <BlockScroller className="sb-page-b scoped-content-builder"
                           blockRef={props.blockRef}
                           blockObj={props.blockObj}
                           htmlId={props.htmlId}
                           setShowExpandButton={props.setShowExpandButton}
                           outsideGrid={props.outsideGrid}>
                <div className="sb-blockContent">
                    <h3 className="sb-page-b-title">{props.blockObj.pageTitle}</h3>
                    <p className="sb-page-b-modified sb-hidden-xs">{moment(props.blockObj.modified).from(moment())}</p>
                    <div className="sb-page-b-content sb-page-body" dangerouslySetInnerHTML={{__html: props.blockObj.pageContent}} />
                </div>
            </BlockScroller>
            <div className="sb-read-more text-center">
                <a href={props.blockObj.pageUrl} className="btn sb-read-more-btn sb-block-link sb-organization-color-element-bg"
                   target="_self" title={`Visit ${title.trim()} Page`}>visit page</a>
            </div>
        </>
    } catch (error) {
        return <ErrorBlock blockObj={{errorMessage: error}} />
    }
}